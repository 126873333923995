import { login } from '@/api/auth'
import { setToken, getToken } from '@/services/auth'
import { setUser } from '@/services/user'

const state = {
  token: getToken()
}

const mutations = {
  SET_TOKEN: (state, token) => state.token = token
}

const actions = {
  async login({ commit }, data) {
    return new Promise(async(resolve, reject) => {
      const res = await login(data)
      if (res.data.success) {
        const token = res.data.data.token
        const user = res.data.data.user
        commit('SET_TOKEN', token)
        commit('user/SET_USER', user, { root: true })
        setToken(token)
        setUser(user)
        resolve()
      } else {
        reject(res.data.message)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}