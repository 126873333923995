import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import auth from './modules/auth'
import user from './modules/user'
import artist from './modules/artist'
import album from './modules/album'
import app from './modules/app'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    user,
    artist,
    album,
    app,
  },
  getters
})

export default store
