import request from '@/utils/request'

 export async function searchArtists(query, opts) {
  opts = Object.assign({
    queryParams: {}
  }, opts)

   const res = await request({
     url: 'https://www.letras.com.br/api/search',
     method: 'get',
     params: {
       fields: 'artists',
       q: query,
       ...opts.queryParams
     }
   })

   return res.data.artists
 }

 export async function getArtists(params) {
  return request({
    url: '/artists',      
    method: 'get',
    params
  })
 }

 export async function getArtist(slug) {
  const res = await request({
    url: `/artists/${slug}`,
    method: 'get'
  })
  return res.data.data
 }

export async function storeArtist(params) {
  const res = await request({
      url: `/artists`,
      method: 'post',
      data: params
    })

  return res.data.data
}

export async function updateArtist(id, data) {
  const form = new FormData()
  for (let i in data) form.append(i, data[i])
  form.append('_method', 'put')

  await request({
    url: `/artists/${id}`,
    method: 'post',
    data: form
  })
}

export async function getArtistSongs(id, params) {
  const res = await request({
    url: `/artists/${id}/songs`,
    method: 'get',
    params
  })

  return res.data.data.data
}

export async function getArtistPictures (id) {
  const res = await request({
    url: `/artists/${id}/pictures`,
    method: 'get'
  })

  return res.data.data
}

export async function deleteArtistPicture (id) {
  await request({
    url: `/artist-pictures/${id}`,
    method: 'delete'
  })
}

export async function storeArtistPictures (id, pictures) {
  const formData = new FormData()

  Array.from(pictures).forEach(picture => formData.append('pictures[]', picture))

  const res = await request({
    url: `/artists/${id}/pictures`,
    method: 'post',
    data: formData
  })

  return res.data.data
}