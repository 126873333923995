<template>
  <div id="main-layout">
    <div id="content-wrap">
      <side-menu />
      <div id="content-box">
        <div id="main-header">
          <div>
            <span></span>
          </div>
          <div class="links-box">
            <a
              href="#"
              @click.prevent="logout"
            >
              <span>Olá, <strong>{{ username }}</strong> <small>(sair)</small></span>
            </a>
          </div>
        </div>
        <div
          id="slot-content-box"
          :class="{loading: $store.getters.isLoading}"
        >
          <loading />
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SideMenu from '@/components/layout/SideMenu'
  import Loading from '@/components/layout/Loading'
  import { deleteToken } from '@/services/auth'
  import { getUser } from '@/services/user'

  export default {
    components: { SideMenu, Loading },
    methods: {
      logout() {
        deleteToken()
        this.$router.push({ name: 'login' })
      }
    },
    computed: {
      username() {
        const user = getUser()
        if (!user) {
          return ''
        }
        return user.nome
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/variables';

  #content-box {
    flex: 1;
  }

  #content-wrap {
    position: relative;
    padding-left: 150px;
  }

  #slot-content-box {
    position: relative;
    height: calc(100vh - 80px);
    background: $contentBgColor;
    overflow: auto;

    &.loading {
      overflow: hidden;
    }
  }

  #main-header {
    display: flex;
    background: $sideMenuBgColor;
    height: 80px;
    justify-content: space-between;
    padding: 0 40px;

    .links-box {
      display: flex;
      align-items: center;

      a {
        flex: 1;
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 700;
        font-style: italic;
      }
    }
  }
</style>