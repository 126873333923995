import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { getToken } from '@/services/auth'


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

service.interceptors.request.use(config => {
  store.commit('app/SET_LOADING', true)
  if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  }
)

service.interceptors.response.use(response => {
  if (response.data.success && response.data.message) {
    Vue.toasted.global.success({ message: response.data.message })
  }
  store.commit('app/SET_LOADING', false)
  return response
}, (error, response) => {
    if (response && response.data && response.data.message) {
      let message = response.data.message
      Vue.toasted.global.error({ message })
    }

    store.commit('app/SET_LOADING', false)

    return Promise.reject(error);
})

export default service
