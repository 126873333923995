import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/layout/MasterLayout'
import AuthGuard from '@/guards/auth-guard'

Vue.use(Router)
 
export const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/login'),
    hidden: true
  }, {
    path: '/',
    component: Layout,
    meta: { name: 'Dashboard', icon: 'fa fa-home' },
    beforeEnter: AuthGuard,
    children: [{
      path: '/index',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/dashboard'),
      beforeEnter: AuthGuard
    }]
  }, {
    path: '/vitrine',
    component: Layout,
    meta: { name: 'Vitrine', icon: 'fa fa-image' },
    children: [{
      path: '/vitrine/index',
      component: () => import('@/views/motd/motd'),
      beforeEnter: AuthGuard
    }]
  }, {
    path: '/moderacao',
    component: Layout,
    meta: { name: 'Moderação', icon: 'fa fa-pencil' },
    children: [{
      path: '/moderacao/aprovacao',
      name: 'songsContributionIndex',
      meta: { name: 'Aprovação músicas' },
      component: () => import('@/views/moderation/song-contribution-index'),
      beforeEnter: AuthGuard
    }, {
      path: '/moderacao/biografias',
      name: 'biographyContributionIndex',
      meta: { name: 'Aprovação biografias' },
      component: () => import('@/views/moderation/biography-correction-index'),
      beforeEnter: AuthGuard
    }, {
      path: '/moderacao/biografia/:id',
      hidden: true,
      name: 'biographyContributionShow',
      meta: { name: 'Aprovação biografias' },
      component: () => import('@/views/moderation/biography-correction-show'),
      beforeEnter: AuthGuard
    }, {
      path: '/moderacao/letras',
      name: 'songsCorrection',
      meta: { name: 'Correção músicas' },
      component: () => import('@/views/moderation/songs-correction-index'),
      beforeEnter: AuthGuard
    }, {
      path: '/moderacao/artistas',
      name: 'artistsCorrection',
      meta: { name: 'Correção artistas' },
      component: () => import('@/views/moderation/songs-correction-index'),
      beforeEnter: AuthGuard
    }]
  }, {
    path: '/moderacao/aprovacao/:id',
    component: Layout,
    hidden: true,
    children: [{
      path: '',
      name: "songContributionShow",
      component: () => import('@/views/moderation/song-contribution-show'),
      beforeEnter: AuthGuard
    }]
  }, {
    path: '/aprovacao/letras/:id',
    component: Layout,
    hidden: true,
    children: [{
      path: '',
      name: "songCorrectionShow",
      component: () => import('@/views/moderation/song-correction-show'),
      beforeEnter: AuthGuard
    }]
  }, {
    path: '/musicas',
    component: Layout,
    meta: { name: 'Músicas', icon: 'fa fa-music' },
    children: [{
      meta: {name: 'Todas músicas'},
      path: '/musicas/index',
      name: 'songsIndex',
      component: () => import('@/views/songs/index'),
      beforeEnter: AuthGuard
    }, {
      meta: {name: 'Nova música'},
      path: '/musica/nova',
      name: 'songStore',
      component: () => import('@/views/songs/song-store'),
      beforeEnter: AuthGuard
    }]
  }, {
    path: '/musica/:id',
    component: Layout,
    hidden: true,
    children: [{
      path: '',
      name: 'songShow',
      component: () => import('@/views/songs/song'),
      beforeEnter: AuthGuard
    }]
  }, {
    path: '/artistas',
    component: Layout,
    meta: { name: 'Artistas', icon: 'fa fa-microphone' },
    children: [{
      meta: {name: 'Todos artistas'},
      path: '/artistas/index',
      component: () => import('@/views/artists/artists-index'),
      beforeEnter: AuthGuard
    }, {
      meta: {name: 'Novo artista'},
      path: '/artista/nova',
      name: 'artistStore',
      component: () => import('@/views/artists/artist-show'),
      beforeEnter: AuthGuard
    }, {
      path: '/artistas/:id',
      name: 'artistShow',
      component: () => import('@/views/artists/artist-show'),
      beforeEnter: AuthGuard,
      hidden: true
    }]
  }, {
    path: '/albuns',
    component: Layout,
    hidden: true,
    children: [{
      name: 'album',
      path: '/albuns/:id',
      props: true,
      component: () => import('@/views/albums/album-form'),
      beforeEnter: AuthGuard
    }]
  }, {
    path: '/playlists',
    component: Layout,
    meta: { name: 'Playlists', icon: 'fa fa-list' },
    children: [{
      meta: {name: 'Todas as playlists'},
      path: '/playlists/index',
      name: 'playlists.index',
      component: () => import('@/views/playlists/playlists-index'),
      beforeEnter: AuthGuard
    }, {
      meta: {name: 'Nova playlist'},
      path: '/playlist/nova',
      component: () => import('@/views/playlists/playlist'),
      beforeEnter: AuthGuard
    }, {
      hidden: true,
      name: 'playlistShow',
      path: '/playlist/:id',
      component: () => import('@/views/playlists/playlist'),
      beforeEnter: AuthGuard
    }]
  }
]

export default new Router({ routes })
