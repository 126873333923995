const state = {
  isLoading: false
}

const mutations = {
  SET_LOADING: (state, isLoading) => state.isLoading = isLoading
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}