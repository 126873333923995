import Vue from 'vue'
import Toasted from 'vue-toasted';

Vue.use(Toasted)

Vue.toasted.register('error', (p) => p.message, {
  type : 'error',
  position: 'bottom-right',
  duration: 2000
})

Vue.toasted.register('success', (p) => p.message, {
  type : 'success',
  position: 'bottom-right',
  duration: 2000
})

