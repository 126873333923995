<template>
  <div id="sidemenu">
    <ul class="menu-list">
      <li 
        v-for="route in routes"
        :key="route.path"
        class="item-1"
      >
        <router-link
          :to="children(route.children).length > 1 ? `` : route.children[0].path"
          class="link-1"
        >
          <i :class="route.meta.icon"></i>
          <span>{{ route.meta.name }}</span>
        </router-link>
        <ul
          v-if="children(route.children).length > 1"
          class="sub"
        >
          <li
            v-for="child in children(route.children)"
            :key="child.path"
            class="item-1"
          >
            <router-link
              :to="child.path"
            >{{ child.meta.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    computed: {
      routes() {
        return this.children(this.$router.options.routes)
      }
    },
    methods: {
      children(c) {
        return c.filter(r => !r.hidden)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/styles/variables";

  #sidemenu {
    position: absolute;
    left: 0;
    height: 100vh;
    width: 150px;
    background: #272c30;
  }

  .menu-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .item-1 {
      position: relative;

      &:hover .sub {
        display: block;
      }

      &:hover .link-1 {
        background: darken(#272c30, 5%);
        text-decoration: none;
      }
    }

    li:not(:last-child) {
        border-bottom: 1px solid rgba(#fff, .070);
    }

    .link-1 {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      font-size: 17px;
      padding: 30px 40px;
      border-radius: 5px;
      transition: .5s all;
      
      span {
        font-weight: 700;
        margin-top: 3px;
      }
    }

    .sub {
      display: none;
      position: absolute;
      background: darken(#272c30, 5%);
      top: 0;
      left: 100%;
      z-index: 10;
      width: 250px;
      box-shadow: 0px 4px 30px rgba(#000, .6);

      a {
        display: block;
        color: #fff;
        padding: 10px 30px;
        font-weight: 700;

        &:hover {
          background: darken(#272c30, 8%);
        }
      }
    }
  }
</style>