import {
  getArtistPictures,
  deleteArtistPicture,
  storeArtistPictures
} from '@/api/artists'

const state = {
  artistPictures: []
}

const mutations = {
  setArtistPictures (state, pictures) {
    state.artistPictures = pictures
  },

  unshiftArtistPictures(state, pictures) {
    state.artistPictures.unshift(pictures)
  },

  removeArtistPicture({ artistPictures }, id) {
    const index = artistPictures.findIndex(p => p.id_foto_artista == id)
    artistPictures.splice(index, 1)
  },
}

const getters = {
  artistPictures ({ artistPictures }) {
    return artistPictures
  }
}

const actions = {
  async fetchArtistPictures ({ commit }, id) {
    const pictures = await getArtistPictures(id)
    commit('setArtistPictures', pictures)
  },

  async destroyArtistPicture ({ commit }, id) {
    await deleteArtistPicture (id)
    commit('removeArtistPicture', id)
  },

  async uploadArtistPictures ({ commit }, { id, pictures }) {
    const uploadedPicures = await storeArtistPictures(id, pictures)

    uploadedPicures.forEach(picture => commit('unshiftArtistPictures', picture))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}